@charset "UTF-8";

@import "../../node_modules/normalize.css/normalize.css";

@import url("https://fonts.googleapis.com/css?family=Roboto&display=swap");

.container {
  max-width: 1300px;
  padding: 0px 10px;
  margin: 0 auto;
}

.block_title {
  font-size: 50px;
}

.title {
  font-size: 35px;
}

.btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  background: #F03C50;
  border-radius: 12px;
  color: #FFFFFF;
  text-decoration: none;
  transition: 0.2s;
  cursor: pointer;
}

.btn:hover {
  background: #df3347;
}

#open-menu {
  cursor: pointer;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

#open-menu div,
#open-menu div:after,
#open-menu div:before {
  background-color: #041F30;
  border-radius: 10px;
  width: 40px;
  height: 5px;
  transition: all 0.15s linear;
}

#open-menu div:before,
#open-menu div:after {
  content: "";
  position: absolute;
}

#open-menu div:before {
  transform: translateY(-200%);
}

#open-menu div:after {
  transform: translateY(200%);
}

#open-menu.open div {
  background: transparent;
}

#open-menu.open div:before {
  transform: rotate(45deg);
  background: #F03C50;
}

#open-menu.open div:after {
  transform: rotate(-45deg);
  background: #F03C50;
}

.news-article {
  background: #FFFFFF;
  box-shadow: 0px 16px 40px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.news-article .news-article__cover {
  display: block;
  text-decoration: none;
  max-height: 200px;
  overflow: hidden;
}

.news-article .news-article__cover img {
  display: block;
  object-fit: cover;
  width: 100%;
  height: 100%;
  min-height: 302px;
  transition: 0.3s;
}

.news-article .news-article__body {
  padding: 15px;
}

.news-article .news-article__body .date {
  display: none;
}

.news-article .news-article__body h3 a {
  display: block;
  color: #16131E;
  margin-bottom: 8px;
  text-decoration: none;
}

.news-article .news-article__body p {
  color: #52555A;
  font-size: 14px;
}

.news-article:hover .news-article__cover img {
  transform: scale(1.1);
}

.news-article:hover .news-article__body h3 a {
  color: #F03C50;
}

.pagination {
  margin-top: 45px;
  text-align: center;
  user-select: none;
}

.pagination a {
  display: inline-block;
  color: #42628B;
  margin-right: 8px;
  text-decoration: none;
  user-select: none;
}

.pagination a:hover {
  text-decoration: underline;
}

.pagination a.active_page {
  color: #F03C50;
}

.pagination a:last-child {
  margin-right: 0;
}

.search_res h2 {
  margin-bottom: 25px;
}

.search_res .search_res__item {
  margin-bottom: 16px;
}

.search_res .search_res__item h3 a {
  display: block;
  margin-bottom: 8px;
  text-decoration: none;
  color: #52555A;
}

.search_res .search_res__item h3 a:hover {
  color: #42628B;
  text-decoration: underline;
}

.search_res .search_res__item p {
  font-size: 14px;
  color: #52555A;
}

.video {
  margin: 32px auto;
}

.video h2 {
  margin-bottom: 32px;
}

.video_wrap {
  width: 100%;
  position: relative;
  height: 0;
  overflow: hidden;
  padding-bottom: 56.25%;
}

.video_wrap iframe,
.video_wrap object,
.video_wrap embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.fancybox__container {
  z-index: 9999999999;
}

.fancybox__thumbs .carousel__slide .fancybox__thumb::after {
  border-color: #F03C50 !important;
  border-width: 3px !important;
}

.gallery {
  margin: 32px auto;
}

.gallery h2 {
  margin-bottom: 32px;
}

.gallery .gallery_items {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  grid-gap: 8px;
  border-radius: 8px;
  overflow: hidden;
}

.gallery .gallery_items a {
  display: block;
  max-height: 300px;
  overflow: hidden;
}

.gallery .gallery_items a img {
  display: block;
  width: 100%;
  height: 100%;
  transition: opacity 0.2s;
  object-fit: cover;
  object-position: 0 0;
}

.gallery .gallery_items a img:hover {
  opacity: 0.7;
}

.page_cover {
  max-width: 600px;
  max-height: 600px;
  object-fit: cover;
  overflow: hidden;
  margin: 0px 0px 32px 0px;
}

.page_cover img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.join form input {
  display: block;
  padding: 14px 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  width: 100%;
  max-width: 500px;
  border-radius: 8px;
}

.join form input:focus {
  border-color: #5d5d5d;
}

.join form .accept {
  margin-top: 25px;
}

.join form .accept label {
  cursor: pointer;
}

.join form .accept label input {
  display: unset;
  width: unset;
  max-width: unset;
  margin-right: 5px;
}

.join form .accept ul {
  margin-top: 10px;
}

.join form .accept ul li {
  margin-bottom: 10px;
}

.join form input[type=submit] {
  width: 140px;
  margin-top: 15px;
  border: 1px solid #F03C50;
}

.print {
  display: flex;
  align-items: center;
  position: absolute;
  right: 0;
  cursor: pointer;
  user-select: none;
}

.print svg {
  width: 30px;
  height: 30px;
  margin-right: 10px;
}

.print p {
  font-weight: bold;
}

.swiper-container {
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  list-style: none;
  padding: 0;
  z-index: 1;
}

.swiper-container-no-flexbox .swiper-slide {
  float: left;
}

.swiper-container-vertical > .swiper-wrapper {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.swiper-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  -o-transition-property: transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
}

.swiper-container-android .swiper-slide,
.swiper-wrapper {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.swiper-container-multirow > .swiper-wrapper {
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.swiper-container-free-mode > .swiper-wrapper {
  -webkit-transition-timing-function: ease-out;
  -o-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
  margin: 0 auto;
}

.swiper-slide {
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 100%;
  height: 100%;
  position: relative;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  -o-transition-property: transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
}

.swiper-slide-invisible-blank {
  visibility: hidden;
}

.swiper-container-autoheight,
.swiper-container-autoheight .swiper-slide {
  height: auto;
}

.swiper-container-autoheight .swiper-wrapper {
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-transition-property: height, -webkit-transform;
  transition-property: height, -webkit-transform;
  -o-transition-property: transform, height;
  transition-property: transform, height;
  transition-property: transform, height, -webkit-transform;
}

.swiper-container-3d {
  -webkit-perspective: 1200px;
  perspective: 1200px;
}

.swiper-container-3d .swiper-cube-shadow,
.swiper-container-3d .swiper-slide,
.swiper-container-3d .swiper-slide-shadow-bottom,
.swiper-container-3d .swiper-slide-shadow-left,
.swiper-container-3d .swiper-slide-shadow-right,
.swiper-container-3d .swiper-slide-shadow-top,
.swiper-container-3d .swiper-wrapper {
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

.swiper-container-3d .swiper-slide-shadow-bottom,
.swiper-container-3d .swiper-slide-shadow-left,
.swiper-container-3d .swiper-slide-shadow-right,
.swiper-container-3d .swiper-slide-shadow-top {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 10;
}

.swiper-container-3d .swiper-slide-shadow-left {
  background-image: -webkit-gradient(linear, right top, left top, from(rgba(0, 0, 0, 0.5)), to(rgba(0, 0, 0, 0)));
  background-image: -webkit-linear-gradient(right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  background-image: -o-linear-gradient(right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  background-image: linear-gradient(to left, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
}

.swiper-container-3d .swiper-slide-shadow-right {
  background-image: -webkit-gradient(linear, left top, right top, from(rgba(0, 0, 0, 0.5)), to(rgba(0, 0, 0, 0)));
  background-image: -webkit-linear-gradient(left, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  background-image: -o-linear-gradient(left, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
}

.swiper-container-3d .swiper-slide-shadow-top {
  background-image: -webkit-gradient(linear, left bottom, left top, from(rgba(0, 0, 0, 0.5)), to(rgba(0, 0, 0, 0)));
  background-image: -webkit-linear-gradient(bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  background-image: -o-linear-gradient(bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  background-image: linear-gradient(to top, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
}

.swiper-container-3d .swiper-slide-shadow-bottom {
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.5)), to(rgba(0, 0, 0, 0)));
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  background-image: -o-linear-gradient(top, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
}

.swiper-container-wp8-horizontal,
.swiper-container-wp8-horizontal > .swiper-wrapper {
  -ms-touch-action: pan-y;
  touch-action: pan-y;
}

.swiper-container-wp8-vertical,
.swiper-container-wp8-vertical > .swiper-wrapper {
  -ms-touch-action: pan-x;
  touch-action: pan-x;
}

.swiper-button-next,
.swiper-button-prev {
  position: absolute;
  top: 50%;
  width: 27px;
  height: 44px;
  margin-top: -22px;
  z-index: 10;
  cursor: pointer;
  background-size: 27px 44px;
  background-position: center;
  background-repeat: no-repeat;
}

.swiper-button-next.swiper-button-disabled,
.swiper-button-prev.swiper-button-disabled {
  opacity: 0.35;
  cursor: auto;
  pointer-events: none;
}

.swiper-button-prev,
.swiper-container-rtl .swiper-button-next {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23007aff'%2F%3E%3C%2Fsvg%3E");
  left: 10px;
  right: auto;
}

.swiper-button-next,
.swiper-container-rtl .swiper-button-prev {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23007aff'%2F%3E%3C%2Fsvg%3E");
  right: 10px;
  left: auto;
}

.swiper-button-prev.swiper-button-white,
.swiper-container-rtl .swiper-button-next.swiper-button-white {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E");
}

.swiper-button-next.swiper-button-white,
.swiper-container-rtl .swiper-button-prev.swiper-button-white {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E");
}

.swiper-button-prev.swiper-button-black,
.swiper-container-rtl .swiper-button-next.swiper-button-black {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23000000'%2F%3E%3C%2Fsvg%3E");
}

.swiper-button-next.swiper-button-black,
.swiper-container-rtl .swiper-button-prev.swiper-button-black {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23000000'%2F%3E%3C%2Fsvg%3E");
}

.swiper-button-lock {
  display: none;
}

.swiper-pagination {
  position: absolute;
  text-align: center;
  -webkit-transition: 0.3s opacity;
  -o-transition: 0.3s opacity;
  transition: 0.3s opacity;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  z-index: 10;
}

.swiper-pagination.swiper-pagination-hidden {
  opacity: 0;
}

.swiper-container-horizontal > .swiper-pagination-bullets,
.swiper-pagination-custom,
.swiper-pagination-fraction {
  bottom: 10px;
  left: 0;
  width: 100%;
}

.swiper-pagination-bullets-dynamic {
  overflow: hidden;
  font-size: 0;
}

.swiper-pagination-bullets-dynamic .swiper-pagination-bullet {
  -webkit-transform: scale(0.33);
  -ms-transform: scale(0.33);
  transform: scale(0.33);
  position: relative;
}

.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}

.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-main {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}

.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-prev {
  -webkit-transform: scale(0.66);
  -ms-transform: scale(0.66);
  transform: scale(0.66);
}

.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-prev-prev {
  -webkit-transform: scale(0.33);
  -ms-transform: scale(0.33);
  transform: scale(0.33);
}

.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-next {
  -webkit-transform: scale(0.66);
  -ms-transform: scale(0.66);
  transform: scale(0.66);
}

.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-next-next {
  -webkit-transform: scale(0.33);
  -ms-transform: scale(0.33);
  transform: scale(0.33);
}

.swiper-pagination-bullet {
  width: 8px;
  height: 8px;
  display: inline-block;
  border-radius: 100%;
  background: #000;
  opacity: 0.2;
}

button.swiper-pagination-bullet {
  border: none;
  margin: 0;
  padding: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.swiper-pagination-clickable .swiper-pagination-bullet {
  cursor: pointer;
}

.swiper-pagination-bullet-active {
  opacity: 1;
  background: #007aff;
}

.swiper-container-vertical > .swiper-pagination-bullets {
  right: 10px;
  top: 50%;
  -webkit-transform: translate3d(0, -50%, 0);
  transform: translate3d(0, -50%, 0);
}

.swiper-container-vertical > .swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 6px 0;
  display: block;
}

.swiper-container-vertical > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic {
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 8px;
}

.swiper-container-vertical > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic .swiper-pagination-bullet {
  display: inline-block;
  -webkit-transition: 0.2s top, 0.2s -webkit-transform;
  transition: 0.2s top, 0.2s -webkit-transform;
  -o-transition: 0.2s transform, 0.2s top;
  transition: 0.2s transform, 0.2s top;
  transition: 0.2s transform, 0.2s top, 0.2s -webkit-transform;
}

.swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 0 4px;
}

.swiper-container-horizontal > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic {
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  white-space: nowrap;
}

.swiper-container-horizontal > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic .swiper-pagination-bullet {
  -webkit-transition: 0.2s left, 0.2s -webkit-transform;
  transition: 0.2s left, 0.2s -webkit-transform;
  -o-transition: 0.2s transform, 0.2s left;
  transition: 0.2s transform, 0.2s left;
  transition: 0.2s transform, 0.2s left, 0.2s -webkit-transform;
}

.swiper-container-horizontal.swiper-container-rtl > .swiper-pagination-bullets-dynamic .swiper-pagination-bullet {
  -webkit-transition: 0.2s right, 0.2s -webkit-transform;
  transition: 0.2s right, 0.2s -webkit-transform;
  -o-transition: 0.2s transform, 0.2s right;
  transition: 0.2s transform, 0.2s right;
  transition: 0.2s transform, 0.2s right, 0.2s -webkit-transform;
}

.swiper-pagination-progressbar {
  background: rgba(0, 0, 0, 0.25);
  position: absolute;
}

.swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
  background: #007aff;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
  -webkit-transform-origin: left top;
  -ms-transform-origin: left top;
  transform-origin: left top;
}

.swiper-container-rtl .swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
  -webkit-transform-origin: right top;
  -ms-transform-origin: right top;
  transform-origin: right top;
}

.swiper-container-horizontal > .swiper-pagination-progressbar,
.swiper-container-vertical > .swiper-pagination-progressbar.swiper-pagination-progressbar-opposite {
  width: 100%;
  height: 4px;
  left: 0;
  top: 0;
}

.swiper-container-horizontal > .swiper-pagination-progressbar.swiper-pagination-progressbar-opposite,
.swiper-container-vertical > .swiper-pagination-progressbar {
  width: 4px;
  height: 100%;
  left: 0;
  top: 0;
}

.swiper-pagination-white .swiper-pagination-bullet-active {
  background: #fff;
}

.swiper-pagination-progressbar.swiper-pagination-white {
  background: rgba(255, 255, 255, 0.25);
}

.swiper-pagination-progressbar.swiper-pagination-white .swiper-pagination-progressbar-fill {
  background: #fff;
}

.swiper-pagination-black .swiper-pagination-bullet-active {
  background: #000;
}

.swiper-pagination-progressbar.swiper-pagination-black {
  background: rgba(0, 0, 0, 0.25);
}

.swiper-pagination-progressbar.swiper-pagination-black .swiper-pagination-progressbar-fill {
  background: #000;
}

.swiper-pagination-lock {
  display: none;
}

.swiper-scrollbar {
  border-radius: 10px;
  position: relative;
  -ms-touch-action: none;
  background: rgba(0, 0, 0, 0.1);
}

.swiper-container-horizontal > .swiper-scrollbar {
  position: absolute;
  left: 1%;
  bottom: 3px;
  z-index: 50;
  height: 5px;
  width: 98%;
}

.swiper-container-vertical > .swiper-scrollbar {
  position: absolute;
  right: 3px;
  top: 1%;
  z-index: 50;
  width: 5px;
  height: 98%;
}

.swiper-scrollbar-drag {
  height: 100%;
  width: 100%;
  position: relative;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  left: 0;
  top: 0;
}

.swiper-scrollbar-cursor-drag {
  cursor: move;
}

.swiper-scrollbar-lock {
  display: none;
}

.swiper-zoom-container {
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
}

.swiper-zoom-container > canvas,
.swiper-zoom-container > img,
.swiper-zoom-container > svg {
  max-width: 100%;
  max-height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
}

.swiper-slide-zoomed {
  cursor: move;
}

.swiper-lazy-preloader {
  width: 42px;
  height: 42px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -21px;
  margin-top: -21px;
  z-index: 10;
  -webkit-transform-origin: 50%;
  -ms-transform-origin: 50%;
  transform-origin: 50%;
  -webkit-animation: swiper-preloader-spin 1s steps(12, end) infinite;
  animation: swiper-preloader-spin 1s steps(12, end) infinite;
}

.swiper-lazy-preloader:after {
  display: block;
  content: "";
  width: 100%;
  height: 100%;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20viewBox%3D'0%200%20120%20120'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20xmlns%3Axlink%3D'http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink'%3E%3Cdefs%3E%3Cline%20id%3D'l'%20x1%3D'60'%20x2%3D'60'%20y1%3D'7'%20y2%3D'27'%20stroke%3D'%236c6c6c'%20stroke-width%3D'11'%20stroke-linecap%3D'round'%2F%3E%3C%2Fdefs%3E%3Cg%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(30%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(60%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(90%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(120%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(150%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.37'%20transform%3D'rotate(180%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.46'%20transform%3D'rotate(210%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.56'%20transform%3D'rotate(240%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.66'%20transform%3D'rotate(270%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.75'%20transform%3D'rotate(300%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.85'%20transform%3D'rotate(330%2060%2C60)'%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E");
  background-position: 50%;
  background-size: 100%;
  background-repeat: no-repeat;
}

.swiper-lazy-preloader-white:after {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20viewBox%3D'0%200%20120%20120'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20xmlns%3Axlink%3D'http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink'%3E%3Cdefs%3E%3Cline%20id%3D'l'%20x1%3D'60'%20x2%3D'60'%20y1%3D'7'%20y2%3D'27'%20stroke%3D'%23fff'%20stroke-width%3D'11'%20stroke-linecap%3D'round'%2F%3E%3C%2Fdefs%3E%3Cg%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(30%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(60%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(90%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(120%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(150%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.37'%20transform%3D'rotate(180%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.46'%20transform%3D'rotate(210%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.56'%20transform%3D'rotate(240%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.66'%20transform%3D'rotate(270%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.75'%20transform%3D'rotate(300%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.85'%20transform%3D'rotate(330%2060%2C60)'%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E");
}

@-webkit-keyframes swiper-preloader-spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes swiper-preloader-spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.swiper-container .swiper-notification {
  position: absolute;
  left: 0;
  top: 0;
  pointer-events: none;
  opacity: 0;
  z-index: -1000;
}

.swiper-container-fade.swiper-container-free-mode .swiper-slide {
  -webkit-transition-timing-function: ease-out;
  -o-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}

.swiper-container-fade .swiper-slide {
  pointer-events: none;
  -webkit-transition-property: opacity;
  -o-transition-property: opacity;
  transition-property: opacity;
}

.swiper-container-fade .swiper-slide .swiper-slide {
  pointer-events: none;
}

.swiper-container-fade .swiper-slide-active,
.swiper-container-fade .swiper-slide-active .swiper-slide-active {
  pointer-events: auto;
}

.swiper-container-cube {
  overflow: visible;
}

.swiper-container-cube .swiper-slide {
  pointer-events: none;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  z-index: 1;
  visibility: hidden;
  -webkit-transform-origin: 0 0;
  -ms-transform-origin: 0 0;
  transform-origin: 0 0;
  width: 100%;
  height: 100%;
}

.swiper-container-cube .swiper-slide .swiper-slide {
  pointer-events: none;
}

.swiper-container-cube.swiper-container-rtl .swiper-slide {
  -webkit-transform-origin: 100% 0;
  -ms-transform-origin: 100% 0;
  transform-origin: 100% 0;
}

.swiper-container-cube .swiper-slide-active,
.swiper-container-cube .swiper-slide-active .swiper-slide-active {
  pointer-events: auto;
}

.swiper-container-cube .swiper-slide-active,
.swiper-container-cube .swiper-slide-next,
.swiper-container-cube .swiper-slide-next + .swiper-slide,
.swiper-container-cube .swiper-slide-prev {
  pointer-events: auto;
  visibility: visible;
}

.swiper-container-cube .swiper-slide-shadow-bottom,
.swiper-container-cube .swiper-slide-shadow-left,
.swiper-container-cube .swiper-slide-shadow-right,
.swiper-container-cube .swiper-slide-shadow-top {
  z-index: 0;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.swiper-container-cube .swiper-cube-shadow {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0.6;
  -webkit-filter: blur(50px);
  filter: blur(50px);
  z-index: 0;
}

.swiper-container-flip {
  overflow: visible;
}

.swiper-container-flip .swiper-slide {
  pointer-events: none;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  z-index: 1;
}

.swiper-container-flip .swiper-slide .swiper-slide {
  pointer-events: none;
}

.swiper-container-flip .swiper-slide-active,
.swiper-container-flip .swiper-slide-active .swiper-slide-active {
  pointer-events: auto;
}

.swiper-container-flip .swiper-slide-shadow-bottom,
.swiper-container-flip .swiper-slide-shadow-left,
.swiper-container-flip .swiper-slide-shadow-right,
.swiper-container-flip .swiper-slide-shadow-top {
  z-index: 0;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.swiper-container-coverflow .swiper-wrapper {
  -ms-perspective: 1200px;
}

@font-face {
  font-family: arnamu;
  src: url("../fonts/arnamu.ttf");
}

@font-face {
  font-family: arnamu_bold;
  src: url("../fonts/arnamu_bold.ttf");
}

@font-face {
  font-family: arnamu_italic_bold;
  src: url("../fonts/arnamu_italic_bold.ttf");
}

@font-face {
  font-family: arnamu_italic;
  src: url("../fonts/arnamu_italic.ttf");
}

.top_header {
  background: #df3347;
  height: 48px;
}

.top_header .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}

.top_header .container .tp_fb a {
  display: flex;
  align-items: center;
  color: #FFFFFF;
  text-decoration: none;
}

.top_header .container .tp_fb a img {
  margin-right: 8px;
  display: block;
}

.top_header .container .tp_details {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 300px;
}

.top_header .container .tp_details .tp_search button {
  border: none;
  background: none;
  cursor: pointer;
}

.top_header .container .tp_details .tp_search .tp_search__modal {
  display: none;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 95959595;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
}

.top_header .container .tp_details .tp_search .tp_search__modal.tp_active__search {
  display: flex;
}

.top_header .container .tp_details .tp_search .tp_search__modal form {
  display: flex;
  padding: 25px;
  margin: 15px;
  background: #FFFFFF;
  border-radius: 8px;
  position: relative;
}

.top_header .container .tp_details .tp_search .tp_search__modal form div {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  right: 5px;
  top: 5px;
  width: 15px;
  height: 15px;
  background: #F03C50;
  color: #FFFFFF;
  border-radius: 8px;
  cursor: pointer;
}

.top_header .container .tp_details .tp_search .tp_search__modal form button {
  background: #42628B;
  padding: 7px;
  border-radius: 0px 8px 8px 0px;
}

.top_header .container .tp_details .tp_search .tp_search__modal form input {
  padding: 7px;
  border-radius: 8px 0px 0px 8px;
  border: 1px solid #E6E9EA;
}

.top_header .container .tp_details .tp_mobile a {
  display: flex;
  align-items: center;
  color: #FFFFFF;
  text-decoration: none;
}

.top_header .container .tp_details .tp_mobile a img {
  margin-right: 8px;
}

.top_header .container .tp_details .tp_lang {
  position: relative;
}

.top_header .container .tp_details .tp_lang button {
  display: flex;
  align-items: center;
  border: none;
  background: none;
  color: #FFFFFF;
  font-weight: bold;
  cursor: pointer;
}

.top_header .container .tp_details .tp_lang button img {
  cursor: pointer;
  margin-left: 16px;
}

.top_header .container .tp_details .tp_lang form {
  display: none;
  position: absolute;
  left: 0;
  top: 34px;
  background: #df3347;
  padding: 10px;
  width: 100%;
  z-index: 9999999;
}

.top_header .container .tp_details .tp_lang form.tp_active__lang {
  display: block;
}

.top_header .container .tp_details .tp_lang form button {
  display: block;
  width: 100%;
  text-align: center;
  margin-bottom: 8px;
  border: none;
  background: none;
  color: #FFFFFF;
  cursor: pointer;
}

.top_header .container .tp_details .tp_lang form button:hover {
  text-decoration: underline;
}

.top_header .container .tp_details .tp_lang form button:last-child {
  margin-bottom: 0px;
}

header#home {
  background: #E6E9EA;
  position: relative;
}

header#home .c-left,
header#home .c-right {
  position: absolute;
}

header#home .c-left img,
header#home .c-right img {
  width: 100%;
  display: block;
}

header#home .c-left {
  top: 0px;
  left: 0px;
}

header#home .c-right {
  right: 0px;
  bottom: 0px;
}

header#home .container {
  overflow: hidden;
  position: relative;
  min-height: 600px;
}

header#home .container .header_slider .swiper-wrapper {
  display: flex;
  align-items: center;
  height: 600px;
}

header#home .container .header_slider .swiper-wrapper .swiper-slide {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 32px;
}

header#home .container .header_slider .swiper-wrapper .swiper-slide .swiper-slide__content {
  flex-basis: 568px;
}

header#home .container .header_slider .swiper-wrapper .swiper-slide .swiper-slide__content h2 {
  font-size: min(36px, max(16px + 2vw));
  margin-bottom: 8px;
  font-weight: normal;
}

header#home .container .header_slider .swiper-wrapper .swiper-slide .swiper-slide__content p {
  color: #52555A;
  font-size: min(16px, max(6px + 2vw));
  margin-bottom: 16px;
  letter-spacing: 1px;
}

header#home .container .header_slider .swiper-wrapper .swiper-slide .swiper-slide__content a {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: #F03C50;
  text-decoration: none;
}

header#home .container .header_slider .swiper-wrapper .swiper-slide .swiper-slide__content a img {
  margin-left: 8px;
}

header#home .container .header_slider .swiper-wrapper .swiper-slide .swiper-slide__content a:hover {
  text-decoration: underline;
}

header#home .container .header_slider .swiper-wrapper .swiper-slide .swiper-slide__cover {
  flex-basis: 700px;
  overflow: hidden;
  min-width: 700px;
}

header#home .container .header_slider .swiper-wrapper .swiper-slide .swiper-slide__cover img {
  width: 700px;
  height: 400px;
  object-fit: cover;
  display: block;
  border-radius: 50px;
}

header#home .container .header_slider .swiper-pagination {
  right: 30px;
  display: flex;
  flex-flow: column;
  align-items: center;
}

header#home .container .header_slider .swiper-pagination span {
  opacity: 0.5;
  border-radius: 8px;
  width: 8px;
  height: 24px;
  background: #F03C50;
  margin: 3px 0px;
}

header#home .container .header_slider .swiper-pagination span.swiper-pagination-bullet-active {
  width: 16px;
  height: 16px;
  background: #F03C50;
  border-radius: 50%;
  border: 2px solid #FFFFFF;
  opacity: 1;
}

header .container h1 {
  margin: 50px 0px 0px 0px;
}

nav {
  padding-top: 8px;
  padding-bottom: 8px;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
  position: sticky;
  top: 0;
  z-index: 999999;
  background: #FFFFFF;
}

nav .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

nav .container .nav-menu {
  display: flex;
  justify-content: space-around;
  width: 680px;
}

nav .container .nav-menu li {
  list-style-type: none;
  position: relative;
}

nav .container .nav-menu li a {
  text-decoration: none;
  color: #041F30;
  padding: 10px;
  text-transform: capitalize;
  font-weight: bold;
}

nav .container .nav-menu li a:hover {
  color: #F03C50;
}

nav .container .nav-menu li a.m_join {
  display: none;
}

nav .container .nav-menu li a.active-link {
  color: #F03C50;
}

nav .container .nav-menu li a.different {
  color: #42628B;
}

nav .container .nav-menu li a.different:hover {
  color: #df3347;
}

nav .container .nav-menu li [data-subMenu] {
  max-height: 0px;
  overflow: hidden;
  position: absolute;
  top: 47px;
  left: 0px;
  width: 280px;
  background: #FFFFFF;
  transform: translateX(-100px);
  text-align: center;
  transition: all 0.3s ease-in;
}

nav .container .nav-menu li [data-subMenu].active_submenu {
  max-height: 400px;
  transition: all 0.3s ease-in;
}

nav .container .nav-menu li [data-subMenu] li a {
  display: block;
  width: 100%;
  margin: 10px 0px;
  text-transform: unset;
  font-weight: unset;
}

nav .container .nav-menu li [data-subMenu] li a:hover {
  color: red;
}

nav .container .nav-menu li [data-subMenu] li a::after,
nav .container .nav-menu li [data-subMenu] li a::before {
  display: none;
}

nav .container #open-menu {
  display: none;
}

main {
  padding: 50px 0px;
}

main .main_about {
  display: flex;
  align-items: center;
  padding: 35px 10px;
  min-height: 500px;
}

main .main_about .main_about__title {
  width: 400px;
  position: relative;
}

main .main_about .main_about__title img {
  display: block;
  position: absolute;
  left: 0;
  top: -100px;
  width: 400px;
  z-index: -1;
}

main .main_about .main_about__article {
  width: 944px;
  margin-left: 50px;
}

main .main_about .main_about__article p {
  margin-bottom: 24px;
  color: #52555A;
}

main .our_team {
  background: #E6E9EA;
  padding: 35px 0px;
}

main .our_team .container .our_team__header {
  margin-bottom: 40px;
}

main .our_team .container .our_team__slider {
  display: flex;
  overflow: hidden;
  position: relative;
  height: 600px;
}

main .our_team .container .our_team__slider .swiper-wrapper {
  width: 100%;
}

main .our_team .container .our_team__slider .swiper-wrapper .swiper-slide {
  display: flex;
  justify-content: space-evenly;
  position: relative;
  top: 40px;
}

main .our_team .container .our_team__slider .swiper-wrapper .swiper-slide .team_cover {
  position: relative;
  max-width: 400px;
  max-height: 400px;
}

main .our_team .container .our_team__slider .swiper-wrapper .swiper-slide .team_cover img {
  z-index: 55;
  display: block;
  position: relative;
  background: #FFFFFF;
  border-radius: 8px;
  top: -20px;
  width: 400px;
  height: 400px;
  object-fit: cover;
  overflow: hidden;
}

main .our_team .container .our_team__slider .swiper-wrapper .swiper-slide .team_cover::before {
  content: "";
  position: absolute;
  top: -40px;
  left: -20px;
  width: 400px;
  height: 400px;
  background: #641332;
  z-index: 1;
  border-radius: 8px;
}

main .our_team .container .our_team__slider .swiper-wrapper .swiper-slide .team_cover::after {
  content: "";
  position: absolute;
  top: 0px;
  right: -20px;
  width: 400px;
  height: 400px;
  background: #42628b;
  z-index: 1;
  border-radius: 8px;
}

main .our_team .container .our_team__slider .swiper-wrapper .swiper-slide .team_details {
  max-width: 500px;
}

main .our_team .container .our_team__slider .swiper-wrapper .swiper-slide .team_details h4,
main .our_team .container .our_team__slider .swiper-wrapper .swiper-slide .team_details h2 {
  color: #041F30;
}

main .our_team .container .our_team__slider .swiper-wrapper .swiper-slide .team_details h4 {
  margin-bottom: 15px;
  font-weight: normal;
}

main .our_team .container .our_team__slider .swiper-wrapper .swiper-slide .team_details h2 {
  margin-bottom: 15px;
}

main .our_team .container .our_team__slider .swiper-wrapper .swiper-slide .team_details p {
  color: #52555A;
  line-height: 30px;
}

main .our_team .container .our_team__slider .swiper-wrapper .swiper-slide .team_details a {
  color: #42628B;
  text-decoration: none;
  display: block;
  margin-top: 15px;
}

main .our_team .container .our_team__slider .swiper-wrapper .swiper-slide .team_details a:hover {
  text-decoration: underline;
}

main .our_team .container .our_team__slider .swiper-wrapper .swiper-slide .team_details a::before {
  content: "— ";
}

main .our_team .container .our_team__slider .swiper-pagination {
  bottom: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

main .our_team .container .our_team__slider .swiper-pagination span {
  opacity: 0.5;
  border-radius: 8px;
  width: 24px;
  height: 8px;
  background: #42628B;
}

main .our_team .container .our_team__slider .swiper-pagination span.swiper-pagination-bullet-active {
  width: 16px;
  height: 16px;
  background: #42628B;
  border-radius: 50%;
  border: 2px solid #FFFFFF;
  opacity: 1;
}

main .main_latest_news {
  padding: 35px 10px;
}

main .main_latest_news .mln_grid {
  display: grid;
  margin-top: 24px;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  grid-gap: 24px;
}

main .main_contact {
  background: #E6E9EA;
  padding: 50px 0px;
  margin: 50px 0px;
  position: relative;
  overflow: hidden;
}

main .main_contact .chrt5,
main .main_contact .chrt6 {
  display: block;
  max-width: 420px;
  height: 100%;
  position: absolute;
  top: 0;
}

main .main_contact .chrt5 {
  left: 0px;
}

main .main_contact .chrt6 {
  right: 0px;
}

main .main_contact .main_contact__block h2 {
  color: #F03C50;
  text-align: center;
  margin-bottom: 24px;
}

main .main_contact .main_contact__block p {
  color: #52555A;
  max-width: 700px;
  margin: 0px auto 40px auto;
  text-align: center;
}

main .main_contact .main_contact__block a {
  display: block;
  width: fit-content;
  margin: 0 auto;
}

main .main_interested {
  padding: 35px 10px;
}

main .main_interested .interested_grid {
  display: grid;
  margin-top: 24px;
  grid-template-columns: repeat(auto-fill, minmax(35%, 1fr));
  grid-gap: 24px;
}

main .main_interested .interested_grid .news-article .news-article__cover {
  display: block;
  max-height: 400px;
  overflow: hidden;
}

main .main_interested .interested_grid .news-article .news-article__cover img {
  width: 100%;
  height: 400px;
  object-fit: cover;
}

main article {
  padding: 25px 0px;
  min-height: 70vh;
}

main article p {
  margin-bottom: 15px;
}

main .category .mln_grid {
  display: grid;
  margin-top: 24px;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  grid-gap: 24px;
}

main .notFound {
  display: flex;
  flex-flow: column;
  justify-content: center;
  text-align: center;
  min-height: 50vh;
}

main .notFound h2 {
  font-size: 40px;
  color: #F03C50;
  margin-bottom: 12px;
}

main .notFound p {
  color: #52555A;
  margin-bottom: 8px;
}

main .contact {
  display: flex;
  justify-content: space-between;
}

main .contact .contact_items {
  margin-right: 20px;
}

main .contact .contact_items h3 {
  margin-bottom: 20px;
}

main .contact .contact_items li {
  list-style-type: none;
}

main .contact .contact_items li a {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  color: #42628B;
  text-decoration: none;
}

main .contact .contact_items li a img {
  display: block;
  background: #F03C50;
  padding: 10px;
  width: 40px;
  height: 40px;
  border-radius: 8px;
  margin-right: 10px;
}

main .contact .contact_items li a:hover {
  text-decoration: underline;
}

main .contact .map {
  width: 800px;
}

main .date {
  display: flex;
  align-items: center;
  margin-top: 24px;
  position: relative;
}

main .date img {
  display: block;
  margin-right: 8px;
}

main .date p {
  color: #42628B;
  font-size: 14px;
}

footer {
  margin-top: 25px;
  background: #df3347;
}

footer .container {
  display: flex;
  justify-content: space-between;
  position: relative;
}

footer .container .footer_block {
  padding: 56px 0px;
}

footer .container .footer_block .footer_logo {
  margin-bottom: 30px;
}

footer .container .footer_block .footer_logo img {
  display: block;
}

footer .container .footer_block .footer_contact {
  margin-bottom: 22px;
}

footer .container .footer_block .footer_contact a {
  display: flex;
  align-items: center;
  color: #FFFFFF;
  text-decoration: none;
}

footer .container .footer_block .footer_contact a img {
  margin-right: 12px;
}

footer .container .footer_block h4 {
  color: #fff;
  margin-bottom: 24px;
}

footer .container .footer_block li {
  display: block;
  list-style-type: none;
}

footer .container .footer_block li a {
  display: block;
  width: 100%;
  color: #fff;
  margin-bottom: 16px;
  text-decoration: none;
}

footer .container .footer_block li a:hover {
  text-decoration: underline;
}

footer .creater {
  display: flex;
  justify-content: space-evenly;
  background: #42628B;
  padding: 10px;
}

footer .creater p {
  color: #FFFFFF;
  font-size: 12px;
}

footer .creater p a {
  color: #E6E9EA;
  text-decoration: none;
  text-decoration: underline;
}

footer .creater p a:hover {
  color: #F03C50;
}

* {
  box-sizing: border-box;
}

body {
  font-size: 16px;
  font-family: "Roboto", sans-serif, arnamu;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
}

ul {
  padding: 0;
}

p {
  line-height: 24px;
}

@media screen and (max-width: 1160px) {
  main .our_team .container .our_team__slider .swiper-wrapper .swiper-slide .team_cover img {
    width: 350px;
    height: 350px;
  }

  main .our_team .container .our_team__slider .swiper-wrapper .swiper-slide .team_cover::before {
    width: 350px;
    height: 350px;
  }

  main .our_team .container .our_team__slider .swiper-wrapper .swiper-slide .team_cover::after {
    width: 350px;
    height: 350px;
  }

  main .our_team .container .our_team__slider {
    height: 500px;
  }

  main .our_team .container .our_team__slider .swiper-wrapper .swiper-slide .team_details {
    margin-left: 50px;
  }
}

@media screen and (max-width: 1140px) {
  header#home .container .header_slider .swiper-wrapper .swiper-slide .swiper-slide__cover {
    flex-basis: 500px;
    min-width: unset;
  }

  header#home .container .header_slider .swiper-wrapper .swiper-slide .swiper-slide__cover img {
    width: 100%;
    height: 100%;
  }
}

@media screen and (max-width: 1024px) {
  nav .container {
    position: relative;
  }

  nav .container .join {
    display: none;
  }

  nav .container #open-menu {
    display: flex;
  }

  nav .container .nav-menu {
    display: block;
    width: 300px;
    height: 100vh;
    background: #df3347;
    position: absolute;
    top: -24px;
    left: -2000px;
    z-index: 9999;
    padding: 25px 10px;
    transition: left 0.2s;
  }

  nav .container .nav-menu.active_nav {
    left: 0px;
  }

  nav .container .nav-menu li a {
    display: block;
    color: #FFFFFF;
  }

  nav .container .nav-menu li a.m_join {
    display: unset;
  }

  nav .container .nav-menu li a:hover {
    color: #fff !important;
  }

  nav .container .nav-menu li a.active-link {
    color: #fff !important;
  }

  nav .container .nav-menu li [data-subMenu] {
    max-height: 0px;
    position: static;
    width: 100%;
    background: unset;
    transform: translateX(0px);
    text-align: unset;
  }

  nav .container .nav-menu li [data-subMenu].active_submenu {
    padding: 0px 0px 0px 28px;
    max-height: 400px;
    transition: all 0.3s ease-in;
  }

  nav .container .nav-menu li [data-subMenu] li a {
    display: block;
    width: 100%;
    margin: 0px 0px 8px 0px;
    padding: 0px 0px;
    color: #FFFFFF;
  }

  main .our_team .container .our_team__slider .swiper-wrapper .swiper-slide .team_cover img {
    width: 300px;
    height: 300px;
  }

  main .our_team .container .our_team__slider .swiper-wrapper .swiper-slide .team_cover::before {
    width: 300px;
    height: 300px;
  }

  main .our_team .container .our_team__slider .swiper-wrapper .swiper-slide .team_cover::after {
    width: 300px;
    height: 300px;
  }

  main .our_team .container .our_team__slider {
    height: 430px;
  }

  main .contact {
    flex-flow: column;
  }

  main .contact .contact_items {
    margin-bottom: 25px;
  }

  main .contact .map {
    width: 100%;
  }

  footer .container {
    flex-wrap: wrap;
  }

  footer .container .footer_block {
    flex-basis: 48%;
    text-align: center;
  }

  footer .container .footer_block.oth .footer_logo {
    margin-bottom: 30px;
  }

  footer .container .footer_block.oth .footer_logo img {
    display: block;
    margin: 0 auto;
  }

  footer .container .footer_block.oth .footer_contact {
    margin-bottom: 12px;
  }

  footer .container .footer_block.oth .footer_contact a {
    justify-content: center;
  }
}

@media screen and (max-width: 1024px) and (max-width: 480px) {
  nav .container .nav-menu {
    width: 280px;
  }
}

@media screen and (max-width: 1024px) and (max-width: 380px) {
  nav .container .nav-menu {
    width: 250px;
  }
}

@media screen and (max-width: 1024px) and (max-width: 350px) {
  nav .container .nav-menu {
    width: 220px;
  }
}

@media screen and (max-width: 980px) {
  header#home .container .header_slider .swiper-wrapper {
    height: 800px;
  }

  header#home .container .header_slider .swiper-wrapper .swiper-slide {
    flex-direction: column;
    justify-content: center;
  }

  header#home .container .header_slider .swiper-wrapper .swiper-slide .swiper-slide__content {
    flex-basis: unset;
    text-align: center;
  }

  header#home .container .header_slider .swiper-wrapper .swiper-slide .swiper-slide__content a {
    display: block;
  }

  header#home .container .header_slider .swiper-wrapper .swiper-slide .swiper-slide__cover {
    flex-basis: unset;
    max-width: 420px;
    max-height: 420px;
  }
}

@media screen and (max-width: 960px) {
  main .our_team .container .our_team__slider .swiper-wrapper .swiper-slide .team_details {
    max-width: 400px;
  }

  main .our_team .container .our_team__slider .swiper-wrapper .swiper-slide .team_details h2 {
    margin-bottom: 10px;
  }

  main .our_team .container .our_team__slider .swiper-wrapper .swiper-slide .team_details a {
    display: block;
    margin-top: 10px;
  }

  main .our_team .container .our_team__slider .swiper-pagination {
    bottom: 20px;
  }
}

@media screen and (max-width: 920px) {
  main .our_team .container .our_team__slider .swiper-wrapper .swiper-slide .team_details h2 {
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 860px) {
  main .our_team {
    padding: 20px 0px 10px 0px;
  }

  main .our_team .container .our_team__slider {
    height: fit-content;
  }

  main .our_team .container .our_team__slider .swiper-wrapper .swiper-slide {
    flex-flow: column;
    align-items: center;
    justify-content: flex-start;
    text-align: center;
    min-height: 740px;
  }

  main .our_team .container .our_team__slider .swiper-wrapper .swiper-slide .team_cover {
    margin-bottom: 35px;
  }

  main .our_team .container .our_team__slider .swiper-wrapper .swiper-slide .team_cover img {
    width: 380px;
    height: 380px;
  }

  main .our_team .container .our_team__slider .swiper-wrapper .swiper-slide .team_cover::before {
    width: 380px;
    height: 380px;
  }

  main .our_team .container .our_team__slider .swiper-wrapper .swiper-slide .team_cover::after {
    width: 380px;
    height: 380px;
  }

  main .our_team .container .our_team__slider .swiper-wrapper .swiper-slide .team_details {
    max-width: unset;
    width: 100%;
    margin-left: 0px;
  }

  main .our_team .container .our_team__slider .swiper-pagination {
    bottom: 10px;
  }

  main .main_contact .chrt5,
  main .main_contact .chrt6 {
    max-width: 320px;
  }
}

@media screen and (max-width: 860px) and (max-width: 610px) {
  main .our_team .container .our_team__slider .swiper-wrapper .swiper-slide .team_cover img {
    width: 320px;
    height: 320px;
  }

  main .our_team .container .our_team__slider .swiper-wrapper .swiper-slide .team_cover::before {
    width: 320px;
    height: 320px;
  }

  main .our_team .container .our_team__slider .swiper-wrapper .swiper-slide .team_cover::after {
    width: 320px;
    height: 320px;
  }
}

@media screen and (max-width: 860px) and (max-width: 460px) {
  main .our_team .container .our_team__slider .swiper-wrapper .swiper-slide .team_cover img {
    width: 300px;
    height: 300px;
  }

  main .our_team .container .our_team__slider .swiper-wrapper .swiper-slide .team_cover::before {
    width: 300px;
    height: 300px;
  }

  main .our_team .container .our_team__slider .swiper-wrapper .swiper-slide .team_cover::after {
    width: 300px;
    height: 300px;
  }
}

@media screen and (max-width: 860px) and (max-width: 440px) {
  main .our_team .container .our_team__slider .swiper-wrapper .swiper-slide .team_cover img {
    width: 270px;
    height: 270px;
  }

  main .our_team .container .our_team__slider .swiper-wrapper .swiper-slide .team_cover::before {
    width: 270px;
    height: 270px;
  }

  main .our_team .container .our_team__slider .swiper-wrapper .swiper-slide .team_cover::after {
    width: 270px;
    height: 270px;
  }
}

@media screen and (max-width: 860px) and (max-width: 350px) {
  main .our_team .container .our_team__slider .swiper-wrapper .swiper-slide .team_cover img {
    width: 250px;
    height: 250px;
  }

  main .our_team .container .our_team__slider .swiper-wrapper .swiper-slide .team_cover::before {
    width: 250px;
    height: 250px;
  }

  main .our_team .container .our_team__slider .swiper-wrapper .swiper-slide .team_cover::after {
    width: 250px;
    height: 250px;
  }

  main .our_team .container .our_team__slider .swiper-wrapper .swiper-slide .team_details p {
    line-height: 25px;
    font-size: 15px;
  }
}

@media screen and (max-width: 840px) {
  .block_title {
    font-size: 45px;
  }
}

@media screen and (max-width: 768px) {
  .block_title {
    font-size: 35px;
    max-width: 100% !important;
    width: 100%;
    text-align: center;
    margin-bottom: 20px;
  }

  .title {
    font-size: 25px;
    max-width: 100% !important;
    width: 100%;
    text-align: center;
    margin-bottom: 20px;
  }

  main .main_about {
    flex-wrap: wrap;
  }

  main .main_about .main_about__title {
    width: 100%;
    position: relative;
  }

  main .main_about .main_about__title img {
    left: 0px;
    top: 0px;
    width: 100%;
  }

  main .main_about .main_about__article {
    width: 100%;
    margin-left: 0px;
  }

  main .main_about .main_about__article p {
    margin-bottom: 24px;
    color: #52555A;
  }

  main .main_interested .interested_grid {
    grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
  }

  footer .container {
    flex-wrap: wrap;
  }

  footer .container .footer_block {
    flex-basis: 100%;
    text-align: center;
  }

  footer .creater {
    flex-wrap: wrap;
    justify-content: center;
  }

  footer .creater p {
    width: 100%;
    text-align: center;
    margin-bottom: 10px;
  }

  footer .creater p:last-child {
    margin-bottom: 0px;
  }
}

@media screen and (max-width: 760px) {
  main .main_contact .chrt5,
  main .main_contact .chrt6 {
    max-width: 280px;
  }
}

@media screen and (max-width: 640px) {
  main .main_latest_news .mln_grid {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  }

  main .category .mln_grid {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  }
}

@media screen and (max-width: 620px) {
  main .main_contact .chrt5,
  main .main_contact .chrt6 {
    max-width: 240px;
  }
}

@media screen and (max-width: 580px) {
  header#home .container .header_slider .swiper-wrapper {
    height: 400px;
  }

  header#home .container .header_slider .swiper-wrapper .swiper-slide .swiper-slide__cover {
    display: none;
  }

  header#home .container {
    min-height: unset;
    height: 400px;
  }

  header#home .c-left {
    display: none;
  }
}

@media screen and (max-width: 560px) {
  main .main_latest_news .mln_grid {
    grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
  }

  main .category .mln_grid {
    grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
  }
}

@media screen and (max-width: 520px) {
  .block_title {
    font-size: 30px;
  }

  .top_header .container .tp_details {
    width: unset;
  }

  .top_header .container .tp_details .tp_search {
    margin-right: 16px;
  }

  .top_header .container .tp_details .tp_search img {
    display: block;
    width: 20px;
    height: 20px;
  }

  .top_header .container .tp_details .tp_mobile {
    margin: 0px 16px;
  }

  .top_header .container .tp_details .tp_mobile img {
    display: block;
    width: 20px;
    height: 20px;
  }

  .top_header .container span {
    display: none;
  }

  .top_header .container .current {
    display: block;
  }

  main .main_latest_news .mln_grid {
    grid-gap: 12px;
    grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
  }

  main .main_contact .chrt5,
  main .main_contact .chrt6 {
    max-width: 200px;
  }

  main .category .mln_grid {
    grid-gap: 12px;
    grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
  }
}

@media screen and (max-width: 440px) {
  main .main_contact .chrt5 {
    max-width: 100%;
  }

  main .main_contact .chrt6 {
    display: none;
  }
}

@media print {
  * {
    font-family: Arial;
  }

  nav,
  .top_header,
  footer,
  .print {
    display: none;
  }

  p {
    line-height: 35px;
  }

  header .container h1 {
    font-size: 26px;
  }

  .video {
    display: none;
  }

  .gallery {
    display: none;
  }

@page {
    margin-top: 15px;
    margin-bottom: 15px;
}

  body {
    padding: 15px;
  }
}